import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UnitService } from '@suswoods/services';
import { UnitProfile } from '@suswoods/models';
import { Router } from '@angular/router';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';

@Component({
  selector: 'app-unit-table',
  templateUrl: './unit-table.component.html',
  styleUrls: ['./unit-table.component.scss']
})
export class UnitTableComponent implements OnInit {

  units: UnitProfile[];
  dataSource: MatTableDataSource<UnitProfile>;
  isLoading: boolean = false;
  displayedColumns: string[] = ['id', 'leaseAmount', 'isOccupied'];
  
  constructor(private unitService: UnitService,
              private router: Router) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.isLoading = true;
    this.unitService.getUnits().pipe().subscribe(units => {
      this.units = units;
      this.dataSource = new MatTableDataSource<UnitProfile>(this.units);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    },
    error => this.isLoading = false
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

  onClickUnit(row) {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.unit}/${AdminRouteNames.profile}/${row.id}`);
  }

}
