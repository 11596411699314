import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BillService } from '@suswoods/services';
import { TenantBills } from '@suswoods/models';
import { BillsDataSource } from '@suswoods/shared/view-all-bills-table/view-all-bills-datasource';
import { tap } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-view-all-bills-table',
  templateUrl: './view-all-bills-table.component.html',
  styleUrls: ['./view-all-bills-table.component.scss']
})
export class ViewAllBillsTableComponent implements OnInit, AfterViewInit {
  @Input() tenantId: number;
  bills: TenantBills[];
  dataSource: BillsDataSource;
  displayedColumns: string[] = ['created', 'description', 'amount', 'status', 'bill', 'receipt'];
  pageSize = 10; // user will not be able to change this as of right now

  constructor(private billService: BillService,
              private router: Router) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.dataSource = new BillsDataSource(this.billService);
    this.dataSource.loadTotalBillsCount(this.tenantId);
    this.dataSource.loadBills(null, null, this.pageSize, this.tenantId);
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap((page) => {
          if (page.previousPageIndex > page.pageIndex) {
            this.loadBillsPage('backward', this.dataSource.getFirstBillId());
          }
          if (page.previousPageIndex < page.pageIndex) {
            this.loadBillsPage('forward', this.dataSource.getLastBillId());
          }
          if (page.previousPageIndex === page.pageIndex) {
            this.loadBillsPage();
          }
        })
      )
      .subscribe();
  }

  loadBillsPage(direction?: string, cursor?: string) {
    this.dataSource.loadBills(cursor, direction, this.paginator.pageSize, this.tenantId);
  }

  onClickReceipt(url: string) {
    window.open(url, '_blank');
  }

  onClickBill(billFile: string) {
    const url = `${environment.apiUrl}/filestore/${billFile}`;
    window.open(url, '_blank');
  }

  showBill(billFile: string) {
    if (billFile === 'null') return false;
    if (billFile === '') return false;
    return true;
  }

}

