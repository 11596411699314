export interface Event {
  title: string;
  start: Date;
  end: Date;
  color: {
    primary: string;
    secondary: string;
  }
  meta: {
    bookedBy: string;
  },
  price: number;
  fee: number;
  isPrivate: boolean;
}

export interface SaveEvent {
  event: Event;
  bookedBy: number;
  stripeId: string;
  amount: number;
}

export enum EventTypes {
  Select = 'Select Event Type',
  Party = 'Party Room',
  Movie = 'Theater Room',
  Gaming = 'Gaming Room',
  Computer = 'Computer Room',
  Gym = 'Gym Room'
}

export interface RoomSettings {
  title: string;
  price: number;
  fee: number;
  duration: number;
  isPrivateOnly: boolean;
  amntOfBookings: number;
  amntOfPeople: number;
}

export const PartySettings = {
  title: 'Party Room',
  price: 50.00,
  fee: 25.00,
  duration: 14399999, // 4 hours
  isPrivateOnly: false,
  amntOfBookings: 3,
  amntOfPeople: 4
}

export const MovieSettings = {
  title: 'Theater Room',
  price: 15.00,
  fee: 10.00,
  duration: 14399999, // 4 hours
  isPrivateOnly: true,
  amntOfBookings: 1,
  amntOfPeople: 4
}

export const GamingSettings = {
  title: 'Gaming Room',
  price: 0,
  fee: 0,
  duration: 7199999, // 2 hours
  isPrivateOnly: true,
  amntOfBookings: 1,
  amntOfPeople: 4
}

export const ComputerSettings = {
  title: 'Computer Room',
  price: 0,
  fee: 0,
  duration: 7199999, // 2hours
  isPrivateOnly: true,
  amntOfBookings: 1,
  amntOfPeople: 4
}

export const GymSettings = {
  title: 'Gym Room',
  price: 0,
  fee: 0,
  duration: 3599999, // 1 hour
  isPrivateOnly: true,
  amntOfBookings: 1,
  amntOfPeople: 2
}
