import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';

import { AdminHomeComponent } from '@suswoods/admin/admin-home/admin-home.component';
import { TenantComponent } from '@suswoods/admin/tenant/tenant.component';
import { TenantProfileComponent } from '@suswoods/admin/tenant/tenant-profile/tenant-profile.component';
import { TenantWaiversComponent } from '@suswoods/admin/tenant/tenant-waivers/tenant-waivers.component';
import { ViewWaiverComponent } from '@suswoods/admin/view-waiver/view-waiver.component';
import { BillsComponent } from '@suswoods/admin/bills/bills.component';
import { TenantAddComponent } from '@suswoods/admin/tenant/tenant-add/tenant-add.component';
import { UnitComponent } from '@suswoods/admin/unit/unit.component';
import { UnitAddComponent } from '@suswoods/admin/unit/unit-add/unit-add.component';
import { UnitProfileComponent } from '@suswoods/admin/unit/unit-profile/unit-profile.component';
import { TenantAllComponent } from '@suswoods/admin/tenant/tenant-all/tenant-all.component';
import { AddWaterBillComponent } from '@suswoods/admin/bills/add-water-bill/add-water-bill.component';
import { AddMiscBillComponent } from '@suswoods/admin/bills/add-misc-bill/add-misc-bill.component';
import { TenantAddOfflineComponent } from '@suswoods/admin/tenant/tenant-add-offline/tenant-add-offline.component';
import { OfflineBillsComponent } from '@suswoods/admin/bills/offline/offline-bills/offline-bills.component';
import { AddOfflineBillComponent } from '@suswoods/admin/bills/offline/add-offline-bill/add-offline-bill.component';
import { ReservationsComponent } from '@suswoods/admin/reservations/reservations.component';
import { AdminLayoutComponent } from '@suswoods/admin/admin-layout/admin-layout.component';

const adminRoutes: Routes = [
  {
    path: '', component: AdminLayoutComponent, children: [
      {
        path: AdminRouteNames.home,
        component: AdminHomeComponent,
        data: { title: 'Dashbaord' }
      },
      {
        path: `${AdminRouteNames.tenant}/:id`,
        component: TenantComponent
      },
      {
        path: AdminRouteNames.tenant,
        redirectTo: AdminRouteNames.home
      },
      {
        path: `${AdminRouteNames.tenant}/${AdminRouteNames.profile}/:id`,
        component: TenantProfileComponent
      },
      {
        path: `${AdminRouteNames.tenant}/${AdminRouteNames.waivers}/:id`,
        component: TenantWaiversComponent
      },
      {
        path: `${AdminRouteNames.tenant}/${AdminRouteNames.viewWaiver}/:id/:waiver`,
        component: ViewWaiverComponent
      },
      {
        path: `${AdminRouteNames.tenant}/${AdminRouteNames.offline}/${AdminRouteNames.bills}/:id`,
        component: OfflineBillsComponent
      },
      {
        path: `${AdminRouteNames.tenant}/${AdminRouteNames.bills}/:id`,
        component: BillsComponent
      },
      {
        path: `${AdminRouteNames.tenant}/${AdminRouteNames.bills}/${AdminRouteNames.add}/${AdminRouteNames.water}/:id`,
        component: AddWaterBillComponent
      },
      {
        path: `${AdminRouteNames.tenant}/${AdminRouteNames.bills}/${AdminRouteNames.add}/${AdminRouteNames.miscBill}/:id`,
        component: AddMiscBillComponent
      },
      {
        path: `${AdminRouteNames.tenant}/${AdminRouteNames.bills}/${AdminRouteNames.addOffline}/${AdminRouteNames.bills}/:id`,
        component: AddOfflineBillComponent
      },
      // {
      //   path: `${AdminRouteNames.tenant}/${AdminRouteNames.viewBill}/:id/:bill`,
      //   component: ViewBillComponent
      // },
      {
        path: `${AdminRouteNames.add}/${AdminRouteNames.tenant}`,
        component: TenantAddComponent,
        data: { title: 'Create Tenant' }
      },
      {
        path: `${AdminRouteNames.addOffline}/${AdminRouteNames.tenant}`,
        component: TenantAddOfflineComponent,
        // data: {title: ''}
      },
      {
        path: `${AdminRouteNames.unit}`,
        component: UnitComponent
      },
      {
        path: `${AdminRouteNames.unit}/${AdminRouteNames.profile}/:id`,
        component: UnitProfileComponent
      },
      {
        path: `${AdminRouteNames.add}/${AdminRouteNames.unit}`,
        component: UnitAddComponent,
        data: { title: 'Create Unit' }
      },
      {
        path: `${AdminRouteNames.all}/${AdminRouteNames.tenant}`,
        component: TenantAllComponent,
        data: { title: 'All Current & Old Tenants' }
      },
      {
        path: `${AdminRouteNames.reservations}`,
        component: ReservationsComponent,
        data: { title: 'Reservation' }
      }
    ]
  },
  {
    path: '**',
    redirectTo: AdminRouteNames.home
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(adminRoutes)
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
