import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Waivers } from '@suswoods/models/waivers.enum';
import { TenantService, AuthenticationService, WaiverService } from '@suswoods/services';
import { TenantProfile, TenantWaivers } from '@suswoods/models';

@Component({
  selector: 'app-tenant-waivers',
  templateUrl: './tenant-waivers.component.html',
  styleUrls: ['./tenant-waivers.component.scss']
})
export class TenantWaiversComponent implements OnInit {
  waivers = [
    {
      name: Waivers.amenities,
      completeDate: null,
    },
    {
      name: Waivers.clubhouse,
      completeDate: null,
    },
    {
      name: Waivers.pet,
      completeDate: null,
    },
    {
      name: Waivers.pool,
      completeDate: null,
    },
    {
      name: Waivers.satellitedish,
      completeDate: null,
    },
    {
      name: Waivers.theather,
      completeDate: null,
    }
  ];
  
  currentWaiver: string = null;
  currentWaiverStatus = false;
  completedWaivers: TenantWaivers[];
  waiverForm: FormGroup;
  tenantId: number;
  tenant: TenantProfile;
  isSaving = false;
  

  constructor(private fb: FormBuilder,
              private tenantService: TenantService,
              private authService: AuthenticationService,
              private waiverService: WaiverService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.tenantId = Number(params.get('id'));
      this.tenantService.getWaiversForTenant(this.tenantId).pipe().subscribe(waivers => {
        this.completedWaivers = waivers;
        this.addCompletedWaivers();
      });
    });
    this.tenantService.getTenants(this.tenantId).subscribe(tenant => {
      this.tenant = tenant[0];
    });
    this.initForm();
  }

  initForm() {
    this.waiverForm = this.fb.group({
      iAgree: [{value: false, disabled: true}, Validators.requiredTrue],
    });
  }

  initCompletedForm() {
    this.waiverForm = this.fb.group({
      iAgree: [{value: true, disabled: true}, Validators.requiredTrue],
    });
  }

  addCompletedWaivers() {
    this.completedWaivers.forEach(cWaiver => {
      this.waivers.forEach(waiver => {
        if (waiver.name === cWaiver.type) {
          waiver.completeDate = cWaiver.completeDate;
        }
      });
    });
  }

  onClickUncompleteWaiver(waiver: string) {
    this.currentWaiver = waiver;
    this.currentWaiverStatus = false;
  }

  onClickCompleteWaiver(waiver: string) {
    this.initCompletedForm();
    this.currentWaiver = waiver;
    this.currentWaiverStatus = true;
  }

  onBackClick() {
    this.currentWaiver = null;
    this.initForm();
  }

  onSubmit() {
  }

}
