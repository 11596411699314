import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { AppRouteNames } from '@suswoods/app-route-names.enum';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from '@suswoods/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  AppRouteNames = AppRouteNames; // I guess this has to be here so I can access the imported enum in the html
  currentPage: string = '';
  isLoggedIn: boolean = false;
  isAdmin = 0;

  constructor(
    private locationStrategy: LocationStrategy,
    private router: Router,
    private authService: AuthenticationService) {
    locationStrategy.onPopState(() => { // handle back/forward button in browser
      this.currentPage = window.location.pathname.substr(1);
    });
    router.events.subscribe((event) => {
      (event instanceof NavigationEnd) && this.handleRouteChange(event.url) // update active page in header
    });
  }

  ngOnInit() {
    this.authService.currentUser.subscribe(user => {
      if (user) {
        this.isLoggedIn = true;
        this.isAdmin = user.isAdmin;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  onLogoutClick() {
    this.authService.logout();
    this.router.navigateByUrl(AppRouteNames.home);
    this.isLoggedIn = false;
    this.isAdmin = 0;
  }

  handleRouteChange(url: string) {
    if ((url.includes(TenantRouteNames.root)) || (url.includes(AdminRouteNames.root))) {
      this.currentPage = '/' + AppRouteNames.login;
    } else {
      this.currentPage = url;
    }
  }

}