import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { UnitProfile } from '@suswoods/models/unit';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  constructor(private http: HttpClient) { }
  // admin functions
  getUnits(id?: string) {
    const endPoint = id ? `${environment.apiUrl}/admin/units/${id}` : `${environment.apiUrl}/admin/units`;
    return this.http.get<UnitProfile[]>(endPoint)
      .pipe(map(units => {
        return units;
      }));
  }

  updateUnit(id, unitProfile) {
    const endPoint = `${environment.apiUrl}/admin/units/${id}`;
    return this.http.put<UnitProfile>(endPoint, unitProfile)
      .pipe(map(result => {
        return result;
      }));
  }

  updateUnitOccupancy(param) {
    const endPoint = `${environment.apiUrl}/admin/unitOccupancy`;
    return this.http.put(endPoint, param)
      .pipe(map(result => {
        return result;
      }));
  }

  addUnit(unitProfile) {
    const endPoint = `${environment.apiUrl}/admin/units`;
    return this.http.post<UnitProfile>(endPoint, unitProfile)
      .pipe(map(result => {
        return result;
      }));
  }

  deleteUnit(id) {
    const endPoint = `${environment.apiUrl}/admin/units/${id}`;
    return this.http.delete(endPoint)
      .pipe(map(result => {
        return result;
      }));
  }

  // tenant functions
  tenantGetUnit(id: string) {
    const endPoint = `${environment.apiUrl}/tenant/units/${id}`;
    return this.http.get<UnitProfile[]>(endPoint)
      .pipe(map(units => {
        return units;
      }));
  }

}
