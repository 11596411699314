import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { StripeService } from "ngx-stripe";
import { TenantService, AuthenticationService } from '@suswoods/services';
import { TenantProfile } from '@suswoods/models/tenant';
import { environment } from '@environments/environment';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';

@Component({
  selector: 'manage-add-ach',
  templateUrl: './manage-ach.component.html',
  styleUrls: ['./manage-ach.component.scss']
})
export class ManageAchComponent implements OnInit {
  tenant: TenantProfile;
  achForm: FormGroup;
  verifyForm: FormGroup;
  achOnFile = false;
  cardOnFile = false;
  bankIsVerified = false;
  isSaving = false;
  isRemoving = false;
  error: string = null;
  manageCreditCard = `../${TenantRouteNames.manageCard}`;
  
  constructor(private fb: FormBuilder,
              //private router: Router,
              private tenantService: TenantService,
              private authService: AuthenticationService,
              private stripeService: StripeService,
              private router: Router) { }

  ngOnInit() {
    this.tenantService.tenantGetTenant(this.authService.currentUserValue.id).subscribe(tenant => { 
      this.tenant = tenant[0];
      if (this.tenant.stripeCCId) {
        this.cardOnFile = true;
        return;
      }
      if (this.tenant.stripeBankId && this.tenant.stripeBankIsVerified == false) {
        this.achOnFile = true;
        this.initVerifyForm();
      }
      if (this.tenant.stripeBankId && this.tenant.stripeBankIsVerified == true) {
        this.bankIsVerified = true;
      } else {
        this.initAchForm();    
      }
    });
  }

  initAchForm() {
    this.achForm = this.fb.group({
      routingNumber: ['', Validators.required], 
      accountNumber: ['', Validators.required],
    });
  }

  initVerifyForm() {
    this.verifyForm = this.fb.group({
      amount1: ['', Validators.required],
      amount2: ['', Validators.required]
    });
  }

  onSubmitAchInfo() {
    this.isSaving = true;
    this.error = null;
    const achData = {
      country: 'US',
      currency: 'usd',
      routing_number: this.achForm.value.routingNumber.toString(),
      account_number: this.achForm.value.accountNumber.toString(),
      account_holder_name: this.tenant.firstName.toUpperCase() + ' ' + this.tenant.lastName.toUpperCase(),
      account_holder_type: 'individual'
    }
    this.stripeService
      .createToken('bank_account', achData)
      .subscribe(result => {
        if (result.token) {
          this.tenantService.updateStripeBankInfo({ 
            id: this.authService.currentUserValue.id,
            paymentInfo: result.token,
            stripeCustomerId: this.tenant.stripeId}).subscribe(result => {
              if (result['message'] == 'fail') {
                this.error = environment.defaultErrorMsg;
                this.isSaving = false;
              }
              this.isSaving = false;
              location.reload();
            });

        } else if (result.error) {
          // Error creating the token
          this.error = result.error.message;
          this.isSaving = false;
        }
      });
  }

  onSubmitVerify() {
    this.isSaving = true;
    this.error = null;
    const data = {
      id: this.tenant.id,
      amount1: parseInt(this.verifyForm.value.amount1),
      amount2: parseInt(this.verifyForm.value.amount2),
      stripeCustomerId: this.tenant.stripeId,
      stripeBankId: this.tenant.stripeBankId
    };
    this.tenantService.verifyStripeBankInfo(data).subscribe((result: any) => {
      this.isSaving = false;
      if (result.message == 'success') {
        this.bankIsVerified = true;
        this.verifyForm = null;
        this.achForm = null;
      } else {
        this.error = result.message;
      }
    });
  }

  onRemoveBankClick() {
    this.isRemoving = true;
    this.error = null;
    const data = {
      id: this.authService.currentUserValue.id,
      stripeCustomerId: this.tenant.stripeId,
      stripeBankId: this.tenant.stripeBankId,
      paymentInfo: {
        id: null,
        bank_account: {
          id: null,
          bank_name: null,
          last4: null
        }
      }
    };
    this.tenantService.removeStripeBankInfo(data).subscribe((result: any) => {
      this.isRemoving = false;
      if (result.message == 'success') {
        location.reload();
      } else {
        this.error = 'Something went wrong';
      }
    }, error => {
      this.isRemoving = false;
      this.error = 'Something went wrong';
    });
  }

  onBackClick() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.home}`);
  }
  
}
