import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TenantService } from '@suswoods/services';
import { TenantProfile } from '@suswoods/models';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';
import { environment } from '@environments/environment';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { pipe } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { requiredFileType } from '@suswoods/admin/file-upload/file-validator';

@Component({
  selector: 'app-add-misc-bill',
  templateUrl: './add-misc-bill.component.html',
  styleUrls: ['./add-misc-bill.component.scss']
})
export class AddMiscBillComponent implements OnInit {
  tenantId: number;
  tenant: TenantProfile;
  progress = 0;
  success = false;
  billForm = new FormGroup({
    amountDue: new FormControl(null, Validators.required),
    billFile: new FormControl(null, requiredFileType(['png', 'jpg', 'jpeg', 'tiff', 'pdf'])),
    type: new FormControl(null, Validators.required),
    userId: new FormControl({value: '', disabled: true}, Validators.required)
  });

  constructor(private router: Router,
              private route: ActivatedRoute,
              private tenantService: TenantService,
              private http: HttpClient) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.tenantId = Number(params.get('id'));
      this.initTenant();
    });
  }

  initTenant() {
    this.tenantService.getTenants(this.tenantId).subscribe(tenant => {
      this.tenant = tenant[0];
      this.billForm.patchValue({userId: this.tenant.firstName + ' ' + this.tenant.lastName});
    });
  }


  onSubmit() {
    const bill = Object.assign({}, this.billForm.getRawValue(), {
      userId: this.tenantId,
      stripeCustomerId: this.tenant.stripeId
    });
    this.success = false;
    if (!this.billForm.valid) {
      markAllAsDirty(this.billForm);
      return;
    }

    this.http.post(`${environment.apiUrl}/admin/bill/addWithFile`, toFormData(bill), {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      uploadProgress(progress => (this.progress = progress)),
      toResponseBody()
    ).subscribe((result: any) => {
      this.progress = 0;
      this.success = true;
      this.billForm.reset();
      if (result.message === 'success') {
        alert('Miscellaneous bill added');
        this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.tenant}/${AdminRouteNames.bills}/${this.tenantId}`);
      } else {
        alert(environment.defaultErrorMsg);
      }
    }, (error) => {
      alert(environment.defaultErrorMsg);
    });
  }

  hasError(field: string, error: string) {
    const control = this.billForm.get(field);
    return control.dirty && control.hasError(error);
  }

}



export function markAllAsDirty(form: FormGroup) {
  for (const control of Object.keys(form.controls)) {
    form.controls[control].markAsDirty();
  }
}

export function toFormData<T>(formValue: T) {
  const formData = new FormData();

  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    formData.append(key, value);
  }
  return formData;
}

export function uploadProgress<T>(cb: (progress: number) => void) {
  return tap((event: HttpEvent<T>) => {
    if (event.type === HttpEventType.UploadProgress) {
      cb(Math.round((100 * event.loaded) / event.total));
    }
  });
}

export function toResponseBody<T>() {
  return pipe(
    filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
    map((res: HttpResponse<T>) => res.body)
  );
}
