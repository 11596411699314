import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnitProfile } from '@suswoods/models';
import { UnitService } from '@suswoods/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-unit-profile',
  templateUrl: './unit-profile.component.html',
  styleUrls: ['./unit-profile.component.scss']
})
export class UnitProfileComponent implements OnInit {
  profileData: UnitProfile;
  profileForm: FormGroup;
  unitId: string;
  isLoading = true;
  saving = false;
  deleting = false;

  constructor(private route: ActivatedRoute,
              private unitService: UnitService,
              private fb: FormBuilder,
              private router: Router) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.unitService.getUnits(params.get('id')).pipe().subscribe(unit => {
        if (!unit[0]) {
          return;
        }
        this.profileData = unit[0];
        this.unitId = unit[0].id;
        this.initForm();
      })
    });
  }

  initForm() {
    this.profileForm = this.fb.group({
      id: [{value: this.profileData.id, disabled: true}],
      leaseAmount: [{value: this.profileData.leaseAmount, disabled: true}, Validators.required],
      isOccupied: [{value: this.profileData.isOccupied ? 'Yes' : 'No', disabled: true}, Validators.required]
    })
    this.isLoading = false;
  }

  onSubmit() { // Removed because nothing can be updated on stripe, only removed and a new unit added
    // this.saving = true;
    // this.unitService.updateUnit(this.unitId, this.profileForm.value).subscribe(result => {
    //   if (result['message'] == 'success') {
    //     this.saving = false;
    //   } else {
    //     this.saving = false;
    //     alert(environment.defaultErrorMsg);
    //   }
    // }, error => {
    //   this.saving = false;
    //   alert(environment.defaultErrorMsg);
    // });
  }

  onDeleteUnitClick() { //TODO I think we will need check if this was subcribed to and if so cancel it and alert the tenant
    this.deleting = true;
    if (confirm('Are you sure you wish to delete ' + this.unitId)) {
      // continue
    } else {
      this.deleting = false;
      return;
    }
    this.unitService.deleteUnit(this.unitId).subscribe((result) => {
      if (result['message'] == 'success') {
        this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.home}`);
      } else {
        this.deleting = false;
        alert(environment.defaultErrorMsg);
      }
    }, error => {
      this.deleting = false;
      alert(environment.defaultErrorMsg);
    });
  }

}
