import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  CalendarEventTitleFormatter
} from 'angular-calendar';
import { EventTitleFormatter } from '@suswoods/shared/event-calendar/event-title-formatter.provider';
import { MatDialog } from '@angular/material/dialog';
import { CreateEventComponent } from '@suswoods/shared/event-calendar/create-event/create-event.component';
import { EventDialogData, Event, TenantProfile, SaveEvent, User, EventTypes } from '@suswoods/models';
import { AuthenticationService, EventCalendarService, TenantService } from '@suswoods/services';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';


const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  },
  lightBlue: {
    primary: '#000000',
    secondary: '#faded8'
  }
};

interface colorData {
  primary: string;
  secondary: string;
}

const offset = new Date().getTimezoneOffset();



@Component({
  selector: 'app-event-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: EventTitleFormatter
    }
  ]
})
export class EventCalendarComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  clickedDate: Date;
  user: User;
  tenant: TenantProfile;
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  constructor(private modal: NgbModal,
              public dialog: MatDialog,
              private authService: AuthenticationService,
              private eventService: EventCalendarService,
              private tenantService: TenantService,
              private router: Router) {}

  ngOnInit() {
    this.user = this.authService.currentUserValue;
    if (this.user.isAdmin !== 1) {
      this.initTenant();
    } else {
      this.initAdmin();
    }
  }

  initTenant() {
    this.tenantService.tenantGetTenant(this.authService.currentUserValue.id).subscribe(tenant => {
      this.tenant = tenant[0];
      this.getEventsByMonthTenant(new Date());
    });
  }

  initAdmin() {
    this.getEventsByMonthAdmin(new Date());
  }

  getEventsByMonthTenant(date: Date) {
    this.events = [];
    this.eventService.getEventsByMonthTenant(date).subscribe((events) => {
      events.forEach(event => {
        this.addEventFromDb(event);
      });
      this.refresh.next();
    });
  }

  getEventsByMonthAdmin(date: Date) {
    this.events = [];
    this.eventService.getEventsByMonthAdmin(date).subscribe((events) => {
      events.forEach(event => {
        this.addEventFromDb(event);
      });
      this.refresh.next();
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    } else {
      //alert('different month')
    }
  }

  onPreviousClick() {
    if (this.user.isAdmin) {
      this.getEventsByMonthAdmin(this.viewDate);
    } else {
      this.getEventsByMonthTenant(this.viewDate);
    }
  }

  onNextClick() {
    if (this.user.isAdmin) {
      this.getEventsByMonthAdmin(this.viewDate);
    } else {
      this.getEventsByMonthTenant(this.viewDate);
    }
    
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    if (!this.user.isAdmin) return;
    // This could be used to interact with the event
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEventFromDb(event: any): void {
    let start = new Date(event.start);
    start.setMinutes(start.getMinutes() - offset);
    let end = new Date(event.end);
    end.setMinutes(end.getMinutes() - offset);
    this.events = [
      ...this.events,
      {
        title: event.title,
        start: start,
        end: end,
        color: this.selectEventColor(event.title),
        meta: {
          bookedBy: event['meta-bookedby']
        }
      }
    ];
  }

  addEvent(event: EventDialogData): void {
    this.events = [
      ...this.events,
      {
        title: event.title,
        start: event.startDate,
        end: event.endDate,
        color: this.selectEventColor(event.title),
        meta: {
          bookedBy: event.bookedBy
        }
      }
    ];
    this.refresh.next();
  }

  selectEventColor(eventTitle: string): colorData {
    if (eventTitle === EventTypes.Party) {
      return colors.blue;
    }
    if (eventTitle === EventTypes.Movie) {
      return colors.yellow;
    }
    return colors.lightBlue;
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  
  openCreateEventDialog(): void {
    if (this.user.isAdmin) return;
    const dialogRef = this.dialog.open(CreateEventComponent, {
      width: '300px',
      data: {startDate: this.clickedDate}
    });
    
    dialogRef.afterClosed().subscribe((result: EventDialogData)=> {
      if (result && result.status === 'saved') {
        const event = this.formatEvent(result);
        this.eventService.saveEvent(event).subscribe((resultOfSave: any) => {
          if (resultOfSave.message === 'success') {
            this.addEvent(result);
            alert('Event saved');
          } else {
            alert(`There was a problem creating event. ${resultOfSave.error}`);
          }
        }, error => {
          alert('Unknown error');
        });
      }
    });
  }

  formatEvent(eventData: EventDialogData): SaveEvent {
    const event: Event = {
      title: eventData.title,
      start: eventData.startDate,
      end: eventData.endDate,
      color: this.getColors(eventData.title),
      meta: {
        bookedBy: eventData.bookedBy
      },
      price: eventData.price,
      fee: eventData.fee,
      isPrivate: eventData.isPrivate
    }
    return {
      event: event,
      bookedBy: this.tenant.id,
      stripeId: this.tenant.stripeId,
      amount: event.price + event.fee
    }
  }



  getColors(eventTitle: string): colorData {
    if (eventTitle === EventTypes.Party) {
      return {
        primary: colors.blue.primary,
        secondary: colors.blue.secondary
      }
    }
    if (eventTitle === EventTypes.Movie) {
      return {
        primary: colors.yellow.primary,
        secondary: colors.yellow.secondary
      }
    }
    return {
      primary: colors.lightBlue.primary,
      secondary: colors.lightBlue.secondary
    }
  }

  onBackClick() {
    if (this.user.isAdmin) {
      this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.home}`);
    } else {
      this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.home}`);
    }
  }

}
