import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@suswoods/services';
import { AppRouteNames } from '@suswoods/app-route-names.enum';
import { TenantRouteNames } from '../tenant-route-names.enum';

@Component({
  selector: 'app-tenant-home',
  templateUrl: './tenant-home.component.html',
  styleUrls: ['./tenant-home.component.scss']
})
export class TenantHomeComponent implements OnInit {

  constructor(private router: Router,
              private authService: AuthenticationService) { }

  ngOnInit() {
  }

  onManageCreditCardClick() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.payment}/${TenantRouteNames.manageCard}`);
  }

  onManageBankAccountClick() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.payment}/${TenantRouteNames.manageAch}`);
  }

  onManageSubscriptionsClick() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.subscription}`);
  }

  onViewBillsClick() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.bill}/${TenantRouteNames.viewAll}`);
  }

  onEventCalendarClick() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.calendar}`);
  }

  onManageWaiversClick() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.manageWaviers}`);
  }

}
