import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';

@Component({
  selector: 'app-tenant-all',
  templateUrl: './tenant-all.component.html',
  styleUrls: ['./tenant-all.component.scss']
})
export class TenantAllComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goback() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.home}`);
  }


}
