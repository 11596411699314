import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';

import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { ViewAllBillsTableComponent } from '@suswoods/shared/view-all-bills-table/view-all-bills-table.component';
import { EventCalendarComponent } from '@suswoods/shared/event-calendar/event-calendar.component';

import {
  MatButtonModule,
  MatToolbarModule,
  MatDialogModule,
  MatTabsModule,
  MatFormFieldModule,
  MatInputModule,
  MatSnackBarModule,
  MatIconModule,
  MatCheckboxModule,
  MatRadioModule,
  MatCardModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatSidenavModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatDatepickerModule,
  MatNativeDateModule
} from '@angular/material';
import { PhonePipe } from '@suswoods/shared/phone.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateEventComponent } from '@suswoods/shared/event-calendar/create-event/create-event.component';
import { CustomDayViewComponent } from '@suswoods/shared/event-calendar/custom-day-view/custom-day-view.component';
import { AmenitiesComponent } from '@suswoods/shared/waivers/amenities/amenities.component';
import { ClubhouseComponent } from '@suswoods/shared/waivers/clubhouse/clubhouse.component';
import { PoolComponent } from '@suswoods/shared/waivers/pool/pool.component';
import { TheaterComponent } from '@suswoods/shared/waivers/theater/theater.component';
import { SatellitedishComponent } from '@suswoods/shared/waivers/satellitedish/satellitedish.component';
import { PetComponent } from '@suswoods/shared/waivers/pet/pet.component';
import { ConfirmComponent } from '@suswoods/shared/dialog/confirm/confirm.component';
import { CovidComponent } from './waivers/covid/covid.component';

@NgModule({
  declarations: [
    PhonePipe,
    ViewAllBillsTableComponent,
    EventCalendarComponent,
    CreateEventComponent,
    CustomDayViewComponent,
    AmenitiesComponent,
    ClubhouseComponent,
    PoolComponent,
    TheaterComponent,
    SatellitedishComponent,
    PetComponent,
    ConfirmComponent,
    CovidComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatCardModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatSidenavModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgbModule,
    FormsModule,
    NgbModalModule,
    FlatpickrModule.forRoot()
  ],
  exports: [
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatCardModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatSidenavModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ReactiveFormsModule,
    PhonePipe,
    MatDatepickerModule,
    MatNativeDateModule,
    ViewAllBillsTableComponent,
    EventCalendarComponent,
    CalendarModule,
    NgbModule,
    FormsModule,
    NgbModalModule,
    FlatpickrModule,
    CustomDayViewComponent,
    AmenitiesComponent,
    ClubhouseComponent,
    PoolComponent,
    TheaterComponent,
    SatellitedishComponent,
    PetComponent
  ],
  entryComponents: [
    CreateEventComponent,
    ConfirmComponent,
    CovidComponent
  ],
  providers: [
    Title
  ]
})
export class SharedModule { }
