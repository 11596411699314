import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TenantService, BillService } from '@suswoods/services';
import { TenantProfile } from '@suswoods/models';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';

@Component({
  selector: 'app-offline-bills',
  templateUrl: './offline-bills.component.html',
  styleUrls: ['./offline-bills.component.scss']
})
export class OfflineBillsComponent implements OnInit {
  tenantId: number;
  tenant: TenantProfile;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private tenantService: TenantService,
              private billService: BillService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.tenantId = Number(params.get('id'));
      this.tenantService.getTenants(this.tenantId).subscribe(user => {
        this.tenant = user[0];
      });
    });
  }

  onAddOfflineBillClick() {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.tenant}/${AdminRouteNames.bills}/${AdminRouteNames.addOffline}/${AdminRouteNames.bills}/${this.tenantId}`);
  }

}