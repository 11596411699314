import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

interface Confirm {
  header?: string;
  message?: string;
}

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  public confirm: Confirm;

  constructor(public dialogRef: MatDialogRef<ConfirmComponent>) { }

  ngOnInit() {
  }

}
