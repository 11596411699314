import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { TenantWaivers } from '@suswoods/models';

interface Result {
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class WaiverService {

  constructor(private http: HttpClient) { }

  // admin functions
  adminGetWaiver() {
    const endPoint = `${environment.apiUrl}/admin/waiver/get`;
    return this.http.get<TenantWaivers[]>(endPoint)
    .pipe(map(events => {
        return events;
      }));
  }
  




  // tenant functions
  saveWaiver(data) {
    const endPoint = `${environment.apiUrl}/tenant/waiver/save`;
    return this.http.post<Result>(endPoint, data)
      .pipe(map(event => {
        return event;
      }));
  }

  tenantGetWaiver() {
    const endPoint = `${environment.apiUrl}/tenant/waiver/get`;
    return this.http.get<TenantWaivers[]>(endPoint)
    .pipe(map(events => {
        return events;
      }));
  }

}
