import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TenantService } from '@suswoods/services';
import { TenantProfile } from '@suswoods/models';
import { Router } from '@angular/router';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';

@Component({
  selector: 'app-tenant-all-table',
  templateUrl: './tenant-all-table.component.html',
  styleUrls: ['./tenant-all-table.component.scss']
})
export class TenantAllTableComponent implements OnInit {
  tenants: TenantProfile[];
  dataSource: MatTableDataSource<TenantProfile>;
  isLoading: boolean = false;
  displayedColumns: string[] = ['unitId', 'name', 'phone', 'isActive'];
  
  constructor(private tenantService: TenantService,
              private router: Router) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.isLoading = true;
    this.tenantService.getAllTenants().pipe().subscribe(tenants => {
      this.tenants = tenants;
      this.dataSource = new MatTableDataSource<TenantProfile>(this.tenants);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    },
    error => this.isLoading = false
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

  onClickTenant(row) {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.tenant}/${row.id}`);
  }


}
