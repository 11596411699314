import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TenantService, AuthenticationService } from '@suswoods/services';
import { EmailParams } from '@suswoods/models';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-request-for-concierge',
  templateUrl: './request-for-concierge.component.html',
  styleUrls: ['./request-for-concierge.component.scss']
})
export class RequestForConciergeComponent implements OnInit {

  conciergeForm: FormGroup;
  fullName: string;
  isSaving = false;

  constructor(private fb: FormBuilder,
              private tenantService: TenantService,
              private authServ: AuthenticationService) { }

  ngOnInit() {
    this.fullName = this.authServ.currentUserValue.firstName + ' ' + this.authServ.currentUserValue.lastName;
    this.conciergeForm = this.fb.group({
      name: [{ value: this.fullName, disabled: true }, Validators.required],
      email: [{ value: this.authServ.currentUserValue.username, disabled: false }, Validators.required],
      subject: [{ value: '', disabled: false }, Validators.required],
      message: [{ value: '', disabled: false }, Validators.required],
    });
  }

  onSendMailClick() {
    this.isSaving = true;
    const emailParams: EmailParams = {
      toAddress: 'concierge', // The address is set server side. This simple tells the server which address to use.
      subject: `${this.conciergeForm.get('name').value}  Concierge Request`,
      body: 'Subject: ' + this.conciergeForm.get('subject').value + '\n<br>' +
            'email: ' + this.conciergeForm.get('email').value + '\n<br>' +
            'message: ' + this.conciergeForm.get('message').value
    }
    this.tenantService.sendEmail(emailParams).subscribe((result) => {
      if (result.status === 'success') {
        this.onSuccess();
      } else {
        this.onFailure();
      }
      this.isSaving = false;
    }, (error) => {
      this.onFailure();
      this.isSaving = false;
    });
  }

  onSuccess() {
    alert('Concierge request sent.');
    this.conciergeForm.reset({
      name: this.fullName,
      email: this.authServ.currentUserValue.username
    });
  }

  onFailure() {
    alert(environment.defaultErrorMsg);
  }

}
