import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-waiver',
  templateUrl: './view-waiver.component.html',
  styleUrls: ['./view-waiver.component.scss']
})
export class ViewWaiverComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      
    });
  }

}
