import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';

@Component({
  selector: 'app-view-all-bills',
  templateUrl: './view-all-bills.component.html',
  styleUrls: ['./view-all-bills.component.scss']
})
export class ViewAllBillsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onBackClick() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.home}`);
  }

}
