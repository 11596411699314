import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { TenantBills } from '@suswoods/models/tenant-bills';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { BillService } from '@suswoods/services/bill.service';

export class BillsDataSource implements DataSource<TenantBills> {

  private bills: TenantBills[];

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private billsSubject = new BehaviorSubject<TenantBills[]>([]);
  private lastBillIdSubject = new BehaviorSubject<string>('');
  private firstBIllIdSubject = new BehaviorSubject<string>('');
  private totalBillsCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public lastBillId$ = this.lastBillIdSubject.asObservable();
  public firstBillId$ = this.firstBIllIdSubject.asObservable();
  public totalBillsCount$ = this.totalBillsCountSubject.asObservable();

  constructor(private billService: BillService) { }

  connect(collectionViewer: CollectionViewer): Observable<TenantBills[]> {
    return this.billsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.billsSubject.complete();
    this.loadingSubject.complete();
    //this.totalBillsCountSubject.complete();
  }

  loadBills(cursor?: string, direction?: string, pageSize?: number, userId?: number) {
    this.loadingSubject.next(true);
    if (userId) {
      this.billService.getBills(userId, cursor, direction, pageSize).pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      ).subscribe(bills => {
        this.bills = bills;
        if (this.bills.length > 0) {
          this.lastBillIdSubject.next(this.bills[this.bills.length - 1].id);
          this.billsSubject.next(bills);
        }
      });
    } else {
      this.billService.tenantGetBills(cursor, direction, pageSize).pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      ).subscribe(bills => {
        this.bills = bills;
        if (this.bills.length > 0) {
          this.lastBillIdSubject.next(this.bills[this.bills.length - 1].id);
          this.billsSubject.next(bills);
        }
      });
    }
  }

  loadTotalBillsCount(userId?: number) {
    if (userId) {
      this.billService.getTotalBillCountForTenant(userId).pipe(
        catchError(() => of())
      ).subscribe((count: any) => {
        this.totalBillsCountSubject.next(count.totalCount);
        this.totalBillsCountSubject.complete();
      });
    } else {
      this.billService.tenantGetTotalBillCountForTenant().pipe(
        catchError(() => of())
      ).subscribe((count: any) => {
        this.totalBillsCountSubject.next(count.totalCount);
        this.totalBillsCountSubject.complete();
      });
    }
  }

  getLastBillId() {
    return (this.bills[this.bills.length - 1].id);
  }

  getFirstBillId() {
    return (this.bills[0].id);
  }
}