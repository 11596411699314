import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '@suswoods/services';
import { User } from '@suswoods/models';
import { AppRouteNames } from '@suswoods/app-route-names.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentUser: User;

  constructor(private router: Router, private authenticationService: AuthenticationService) {
      this.authenticationService.currentUser.subscribe(user => this.currentUser = user);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigateByUrl(AppRouteNames.login);
  }
}
