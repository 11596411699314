import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Event } from '@suswoods/models';

interface Result {
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class EventCalendarService {

  constructor(private http: HttpClient) { }

  // admin functions
  getEventsByMonthAdmin(date: Date) {
    const endPoint = `${environment.apiUrl}/admin/event/get-events`;
    return this.http.get<Event[]>(endPoint, {
      params: new HttpParams()
      .set('date', date.toISOString())
    }).pipe(map(events => {
        return events;
      }));
  }





  // tenant functions
  saveEvent(data) {
    const endPoint = `${environment.apiUrl}/tenant/event/event-save`;
    return this.http.post<Result>(endPoint, data)
      .pipe(map(event => {
        return event;
      }));
  }

  getEventsByMonthTenant(date: Date) {
    const endPoint = `${environment.apiUrl}/tenant/event/get-events`;
    return this.http.get<Event[]>(endPoint, {
      params: new HttpParams()
      .set('date', date.toISOString())
    }).pipe(map(events => {
        return events;
      }));
  }

}
