import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resident-services',
  templateUrl: './resident-services.component.html',
  styleUrls: ['./resident-services.component.scss']
})
export class ResidentServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
