import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TenantService, BillService } from '@suswoods/services';
import { TenantProfile } from '@suswoods/models';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.scss']
})
export class BillsComponent implements OnInit {
  tenantId: number;
  user: TenantProfile;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private tenantService: TenantService,
              private billService: BillService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.tenantId = Number(params.get('id'));
      this.tenantService.getTenants(this.tenantId).subscribe(user => {
        this.user = user[0];
      });
    });
  }



  onAddWaterBillClick() {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.tenant}/${AdminRouteNames.bills}/${AdminRouteNames.add}/${AdminRouteNames.water}/${this.tenantId}`);
  }

  onAddMiscBillClick() {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.tenant}/${AdminRouteNames.bills}/${AdminRouteNames.add}/${AdminRouteNames.miscBill}/${this.tenantId}`);
  }



}
