import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { TenantProfile, TenantWaivers, TenantBills, EmailParams, EmailResult } from '@suswoods/models';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor(private http: HttpClient) { }
  // admin functions
  getTenants(id?: number) {
    const endPoint = id ? `${environment.apiUrl}/admin/tenants/tenants/${id}` : `${environment.apiUrl}/admin/tenants/tenants`;
    return this.http.get<TenantProfile[]>(endPoint)
      .pipe(map(tenants => {
        return tenants;
      }));
  }

  getAllTenants() {
    const endPoint = `${environment.apiUrl}/admin/tenants/all`;
    return this.http.get<TenantProfile[]>(endPoint)
      .pipe(map(tenants => {
        return tenants;
      }));
  }

  getWaiversForTenant(id: number) {
    const endPoint = `${environment.apiUrl}/admin/tenants/waivers/${id}`;
    return this.http.get<TenantWaivers[]>(endPoint)
      .pipe(map(waivers => {
        return waivers;
      }));
  }

  getBillsForTenant(id: number) {
    const endPoint = `${environment.apiUrl}/admin/tenants/bills/${id}`;
    return this.http.get<TenantBills[]>(endPoint)
      .pipe(map(bills => {
        return bills;
      }));
  }

  updateTenant(tenantProfile) {
    const endPoint = `${environment.apiUrl}/admin/tenants/tenants/${tenantProfile.id}`;
    return this.http.put<TenantProfile>(endPoint, tenantProfile)
      .pipe(map(result => {
        return result;
      }));
  }

  // tenant functions
  tenantGetTenant(id?: number) {
    const endPoint = `${environment.apiUrl}/tenant/tenant/${id}`;
    return this.http.get<TenantProfile[]>(endPoint)
      .pipe(map(tenants => {
        return tenants;
      }));
  }

  updateStripeCCInfo(data) {
    const endPoint = `${environment.apiUrl}/tenant/tenant/updateStripeCCInfo`;
    return this.http.put(endPoint, data)
      .pipe(map(result => {
        return result;
      }));
  }

  removeStripeCCInfo(data) {
    const endPoint = `${environment.apiUrl}/tenant/tenant/removeStripeCCInfo`;
    return this.http.put(endPoint, data)
      .pipe(map(result => {
        return result;
      }));
  }

  updateStripeBankInfo(data) {
    const endPoint = `${environment.apiUrl}/tenant/tenant/updateStripeBankInfo`;
    return this.http.put(endPoint, data)
      .pipe(map(result => {
        return result;
      }));
  }

  verifyStripeBankInfo(data) {
    const endPoint = `${environment.apiUrl}/tenant/tenant/verifyStripeBankInfo`;
    return this.http.put(endPoint, data)
      .pipe(map(result => {
        return result;
      }));
  }

  removeStripeBankInfo(data) {
    const endPoint = `${environment.apiUrl}/tenant/tenant/removeStripeBankInfo`;
    return this.http.put(endPoint, data)
      .pipe(map(result => {
        return result;
      }));
  }

  sendEmail(emailParams: EmailParams) {
    const endPoint = `${environment.apiUrl}/tenant/tenant/sendEmail`;
    return this.http.post<EmailResult>(endPoint, emailParams)
      .pipe(map(results => {
        return results;
      }));
  }

  

}