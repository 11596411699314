import { Component, OnInit } from '@angular/core';
import { AppRouteNames } from '@suswoods/app-route-names.enum';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import dayGridPlugin from '@fullcalendar/daygrid';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  calendarPlugins = [dayGridPlugin];

  calendarEvents = [
    {
      start: '2019-06-11T10:00:00',
      end: '2019-06-11T16:00:00',
      rendering: 'background',
      color: '#0068ff'
    },
    {
      start: '2019-06-13T10:00:00',
      end: '2019-06-13T16:00:00',
      rendering: 'background',
      color: '#0068ff'
    },
    {
      start: '2019-06-24',
      end: '2019-06-28',
      overlap: false,
      rendering: 'background'
    },
    {
      start: '2019-06-06',
      end: '2019-06-08',
      overlap: false,
      rendering: 'background'
    }
  ];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    autoplay: true,
    dots: true,
    navSpeed: 700,
    navText: ['<img src="../../assets/img/leftarrow.png" />', '<img src="../../assets/img/rightarrow.png" />'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  };
  bigCarouselOption: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    autoplay: true,
    dots: true,
    navSpeed: 700,
    navText: ['<img src="../../assets/img/leftarrow.png" />', '<img src="../../assets/img/rightarrow.png" />'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  };

  constructor(private router: Router, private title: Title) { }

  ngOnInit() {
    this.title.setTitle('The Greens of Sylvania: Luxury Townhomes');
  }

  onClick() {
    this.router.navigateByUrl(AppRouteNames.contactUs);
  }
}
