import { LOCALE_ID, Inject } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { DatePipe } from '@angular/common';

export class EventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  // you can override any of the methods defined in the parent class

  month(event: CalendarEvent): string {
    const bookedBy = event.meta ? event.meta.bookedBy ? `<br>Created By: ${event.meta.bookedBy}` : '' : '';
    return `<b>${new DatePipe(this.locale).transform(
      event.start,
      'h:mm a',
      this.locale
    )}</b> ${event.title}${bookedBy}`;
  }

  week(event: CalendarEvent): string {
    const bookedBy = event.meta ? event.meta.bookedBy ? `<br>Created By: ${event.meta.bookedBy}` : '' : '';
    return `<b>${new DatePipe(this.locale).transform(
      event.start,
      'h:mm a',
      this.locale
    )}</b> ${event.title}${bookedBy}`;
  }

  day(event: CalendarEvent): string {
    const bookedBy = event.meta ? event.meta.bookedBy ? `<br>Created By: ${event.meta.bookedBy}` : '' : '';
    return `<b>${new DatePipe(this.locale).transform(
      event.start,
      'h:mm a',
      this.locale
    )}</b> ${event.title}${bookedBy}`;
  }
}
