import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';
import { AuthenticationService } from '@suswoods/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  user: object;
  error = '';

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService) {
    // redirect to home if logged in
    if (this.authService.currentUserValue && this.authService.currentUserValue.isAdmin === 1) {
      this.router.navigateByUrl(`${AdminRouteNames.root}`);
    }
    if (this.authService.currentUserValue && this.authService.currentUserValue.isAdmin !== 1) {
      this.router.navigateByUrl(`${TenantRouteNames.root}`);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required], //TODO change to .email
      password: ['', Validators.required]
    });
  }

  // access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data == 'Username or password is incorrect') {
            this.error = data;
            this.loading = false;
          } else {
            this.user = data;
            this.navigate();
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  navigate() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
    if (this.user['isAdmin'] === 1) {
      if ((this.returnUrl.includes('tenant')) && (!this.returnUrl.includes('admin'))) { // make we don't redirect to a tenant page that can't be reached by admin
        this.returnUrl = AdminRouteNames.root;
      } else {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || AdminRouteNames.root;
      }
    }
    if (this.user['isAdmin'] !== 1) {
      if (this.returnUrl.includes('admin')) { // make sure we don't redirect to a admin page that can't be reached by tenant
        this.returnUrl = TenantRouteNames.root;
      } else {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || TenantRouteNames.root;
      }
    }
    this.router.navigateByUrl(this.returnUrl);
  }
}
