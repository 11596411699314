import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TenantRoutingModule } from '@suswoods/tenant/tenant-routing.module';
import { SharedModule } from '@suswoods/shared/shared.module';
import { TenantService } from '@suswoods/services';

import { TenantHomeComponent } from '@suswoods/tenant/tenant-home/tenant-home.component';
import { ManageCardComponent } from '@suswoods/tenant/payment/manage-card/manage-card.component';
import { TenantSubscriptionComponent } from '@suswoods/tenant/subscription/tenant-subscription/tenant-subscription.component';
import { TenantSubscriptionLeaseEnrollComponent } from '@suswoods/tenant/subscription/tenant-subscription-lease-enroll/tenant-subscription-lease-enroll.component';
import { ViewAllBillsComponent } from '@suswoods/tenant/bill/view-all-bills/view-all-bills.component';
import { ManageAchComponent } from '@suswoods/tenant/payment/manage-ach/manage-ach.component';
import { ManageWaviersComponent } from '@suswoods/tenant/manage-waviers/manage-waviers.component';
import { TenantLayoutComponent } from '@suswoods/tenant/tenant-layout/tenant-layout.component';
import { RequestForMaintainessComponent } from '@suswoods/tenant/request-for-maintainess/request-for-maintainess.component';
import { RequestForConciergeComponent } from '@suswoods/tenant/request-for-concierge/request-for-concierge.component';

@NgModule({
  declarations: [
    TenantHomeComponent,
    ManageCardComponent,
    TenantSubscriptionComponent,
    TenantSubscriptionLeaseEnrollComponent,
    ViewAllBillsComponent,
    ManageAchComponent,
    ManageWaviersComponent,
    TenantLayoutComponent,
    RequestForMaintainessComponent,
    RequestForConciergeComponent,
    //ViewAllBillsTableComponent
  ],
  imports: [
    CommonModule,
    TenantRoutingModule,
    SharedModule,
  ],
  providers: [TenantService]
})
export class TenantModule { }
