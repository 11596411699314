import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@suswoods/models';
import { UserService } from '@suswoods/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';
import { environment } from '@environments/environment';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';

@Component({
  selector: 'app-tenant-add',
  templateUrl: './tenant-add.component.html',
  styleUrls: ['./tenant-add.component.scss']
})
export class TenantAddComponent implements OnInit {
  userData: User
  userForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.userForm = this.fb.group({
      username: ['', Validators.required], //TODO change to .email
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onAddOfflineTenantClick() {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.addOffline}/${AdminRouteNames.tenant}`);
  }

  onSubmit() {
    this.userService.addUser(this.userForm.value)
      .pipe()
      .subscribe(data => {
        if (data['message'] === 'success') {
          this.router.navigateByUrl(`${AdminRouteNames.root}`);
        } else {
          alert(data['message']);
        }
      }, error => {
        alert(environment.defaultErrorMsg);
      });
  }

  goback() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.home}`);
  }
}
