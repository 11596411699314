import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@suswoods/models';
import { UserService } from '@suswoods/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-tenant-add-offline',
  templateUrl: './tenant-add-offline.component.html',
  styleUrls: ['./tenant-add-offline.component.scss']
})
export class TenantAddOfflineComponent implements OnInit {
  userData: User
  userForm: FormGroup;
  
  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private fb: FormBuilder,
              private router: Router) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    });
  }

  onAddOfflineTenantClick() {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.addOffline}/${AdminRouteNames.tenant}`);
  }

  onSubmit() {
    this.formatUser();
    this.userService.addOfflineUser(this.formatUser())
      .pipe()
      .subscribe(data => {
        if (data['message'] === 'success') {
          this.router.navigateByUrl(`${AdminRouteNames.root}`);
        } else {
          alert(data['message']);
        }
      }, error => {
        alert(environment.defaultErrorMsg);
      }); 
  }

  formatUser() {
    return {
      username: this.userForm.getRawValue().firstName + ' ' + this.userForm.getRawValue().lastName,
      firstName: this.userForm.getRawValue().firstName,
      lastName: this.userForm.getRawValue().lastName,
      password: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    }
  }

}
