import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TenantService, AuthenticationService } from '@suswoods/services';
import { EmailParams, TenantProfile } from '@suswoods/models';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-request-for-maintainess',
  templateUrl: './request-for-maintainess.component.html',
  styleUrls: ['./request-for-maintainess.component.scss']
})
export class RequestForMaintainessComponent implements OnInit {

  maintenanceForm: FormGroup;
  fullName: string;
  isSaving = false;
  profileData: TenantProfile;

  constructor(private fb: FormBuilder,
              private tenantService: TenantService,
              private authServ: AuthenticationService) { }

  ngOnInit() {
    this.fullName = this.authServ.currentUserValue.firstName + ' ' + this.authServ.currentUserValue.lastName;
    this.maintenanceForm = this.fb.group({
      name: [{ value: this.fullName, disabled: true }, Validators.required],
      email: [{ value: this.authServ.currentUserValue.username, disabled: false }, Validators.required],
      subject: [{ value: '', disabled: false }, Validators.required],
      message: [{ value: '', disabled: false }, Validators.required],
    });
    this.initTenant();
  }

  initTenant() {
    this.tenantService.tenantGetTenant(Number(this.authServ.currentUserValue.id)).pipe().subscribe(tenant => {
      if (!tenant[0]) {
        alert("Couldn't find tenant");
        return;
      }
      this.profileData = tenant[0];
    });
  }

  onSendMailClick() {
    this.isSaving = true;
    const emailParams: EmailParams = {
      toAddress: 'maintenance', // The address is set server side. This simple tells the server which address to use.
      subject: `${this.maintenanceForm.get('name').value}  Maintenance Request`,
      body: 'Subject: ' + this.maintenanceForm.get('subject').value + '\n<br>' +
            'email: ' + this.maintenanceForm.get('email').value + '\n<br>' +
            'address: ' + this.profileData.unitId + '\n<br>' +
            'phone: ' + this.profileData.phone + '\n<br>' +
            'message: ' + this.maintenanceForm.get('message').value
    }
    console.log(emailParams);
    this.tenantService.sendEmail(emailParams).subscribe((result) => {
      if (result.status === 'success') {
        this.onSuccess();
      } else {
        this.onFailure();
      }
      this.isSaving = false;
    }, (error) => {
      this.onFailure();
      this.isSaving = false;
    });
  }

  onSuccess() {
    alert('Maintenance request sent.');
    this.maintenanceForm.reset({
      name: this.fullName,
      email: this.authServ.currentUserValue.username
    });
  }

  onFailure() {
    alert(environment.defaultErrorMsg);
  }

}
