export enum TenantRouteNames {
  root = 'tenant',
  home = '',
  payment = 'payment',
  manageCard = 'manage-card',
  manageAch = 'manage-ach',
  subscription = 'subscription',
  enroll = 'enroll',
  lease = 'lease',
  bill = 'bill',
  viewAll = 'view-all',
  calendar = 'calendar',
  manageWaviers = 'manage-waviers',
  maintenanceRequest = 'request-maintenance',
  conciergeRequest = 'request-concierge'
}
