import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { TenantBills } from '@suswoods/models';

interface Result {
  message: string
}

@Injectable({
  providedIn: 'root'
})
export class BillService {

  constructor(private http: HttpClient) { }
  // admin functions
  addBillForTenant(billData) {
    const endPoint = `${environment.apiUrl}/admin/bill/add`;
    return this.http.post<TenantBills>(endPoint, billData).pipe(map(result => {
      return result;
    }));
  }

  addOfflineBillForTenant(billData) {
    const endPoint = `${environment.apiUrl}/admin/offline-bill/add`;
    return this.http.post<Result>(endPoint, billData).pipe(map(result => {
      return result;
    }));
  }

  getBills(userId?: number, lastBillId?: string, direction?: string, pageSize?: number) {
    const endPoint = `${environment.apiUrl}/admin/bill/all`;
    return this.http.get<TenantBills[]>(endPoint, {
      params: new HttpParams()
        .set('lastBillId', lastBillId ? lastBillId : null)
        .set('direction', direction ? direction : null)
        .set('pageSize', pageSize ? pageSize.toString() : null)
        .set('userId', userId.toString())
    }).pipe(map(tenants => {
      return tenants;
    }));
  }

  getOfflineBills(userId?: number) {
    const endPoint = `${environment.apiUrl}/admin/offline-bill/all`;
    return this.http.get<TenantBills[]>(endPoint, {
      params: new HttpParams()
        .set('userId', userId.toString())
    }).pipe(map(tenants => {
      return tenants;
    }));
  }

  getTotalBillCountForTenant(userId: number) {
    const endPoint = `${environment.apiUrl}/admin/bill/count`;
    return this.http.get<TenantBills[]>(endPoint, {
      params: new HttpParams()
        .set('userId', userId.toString())
    }).pipe(map(tenants => {
      return tenants;
    }));
  }


  // tenant functions
  tenantGetBills(lastBillId?: string, direction?: string, pageSize?: number) {
    const endPoint = `${environment.apiUrl}/tenant/bill/all`;
    return this.http.get<TenantBills[]>(endPoint, {
      params: new HttpParams()
        .set('lastBillId', lastBillId ? lastBillId : null)
        .set('direction', direction ? direction : null)
        .set('pageSize', pageSize ? pageSize.toString() : null)
    }).pipe(map(tenants => {
      return tenants;
    }));
  }

  tenantGetTotalBillCountForTenant() {
    const endPoint = `${environment.apiUrl}/tenant/bill/count`;
    return this.http.get<TenantBills[]>(endPoint).pipe(map(tenants => {
      return tenants;
    }));
  }

}
