import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { User } from '@suswoods/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getById(userId:number) {
    return this.http.post<User[]>(`${environment.apiUrl}/admin/users`, {userId:userId});
  }

  addUser(user) {
    return this.http.post<User[]>(`${environment.apiUrl}/admin/users/add`, user);
  }

  updateUser(user) {
    return this.http.post<User[]>(`${environment.apiUrl}/admin/users/update`, user);
  }

  addOfflineUser(user) {
    return this.http.post<User[]>(`${environment.apiUrl}/admin/users/add-offline`, user);
  }

  disableUser(userId: number) {
    const endPoint = `${environment.apiUrl}/admin/users/disableUser`;
    return this.http.put(endPoint, {userId: userId})
      .pipe(map(result => {
        return result;
      }));
  }

  enableUser(userId: number) {
    const endPoint = `${environment.apiUrl}/admin/users/enableUser`;
    return this.http.put(endPoint, {userId: userId})
      .pipe(map(result => {
        return result;
      }));
  }
}