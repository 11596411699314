import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from '@suswoods/app.component';
import { AppRoutingModule } from '@suswoods/app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from '@suswoods/shared';
import { AdminModule } from '@suswoods/admin/admin.module';
import { TenantModule } from '@suswoods/tenant/tenant.module';
import { SharedModule } from '@suswoods/shared/shared.module';
import { environment } from '@environments/environment';
import {
  UserService,
  AuthenticationService,
  UnitService,
  TenantService,
  SubscriptionService,
  BillService
} from '@suswoods/services';
import { NgxStripeModule } from 'ngx-stripe';

import { HomeComponent } from '@suswoods/public/home/home.component';
import { LoginComponent } from '@suswoods/public/login/login.component';
import { ContactUsComponent } from '@suswoods/public/contact-us/contact-us.component';
import { LayoutComponent } from '@suswoods/layout/layout.component';
import { HeaderComponent } from '@suswoods/layout/header/header.component';
import { FooterComponent } from '@suswoods/layout/footer/footer.component';
import { AboutUsComponent } from '@suswoods/public/about-us/about-us.component';
import { ResidentServicesComponent } from '@suswoods/public/resident-services/resident-services.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PublicLayoutComponent } from './public/public-layout/public-layout.component';
import { FullCalendarModule } from '@fullcalendar/angular';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AdminModule,
    TenantModule,
    SharedModule,
    CarouselModule,
    NgxStripeModule.forRoot(environment.stripe.publicKey),
    FullCalendarModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ContactUsComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    AboutUsComponent,
    ResidentServicesComponent,
    PublicLayoutComponent
  ],
  providers: [
    UserService,
    AuthenticationService,
    TenantService,
    UnitService,
    SubscriptionService,
    BillService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
