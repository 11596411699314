import { Component, OnInit } from '@angular/core';
import { User } from '@suswoods/models';
import { AuthenticationService } from '@suswoods/services';
import { Router } from '@angular/router';
import { AppRouteNames } from '@suswoods/app-route-names.enum';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';


@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {
  loading = false;
  currentUser: User;
  users: User[];
  title = 'Dashabord';

  constructor(
    private authService: AuthenticationService,
    private router: Router) { }

  ngOnInit() {
    this.currentUser = this.authService.currentUserValue;
  }

  onLogoutClick() {
    this.authService.logout();
    this.router.navigateByUrl(AppRouteNames.home);
  }

  onTenantClick(id) {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.tenant}/${id}`);
  }

  onAddTenantClick() {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.add}/${AdminRouteNames.tenant}`);
  }

  onAddUnitClick() {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.add}/${AdminRouteNames.unit}`);
  }

  onViewAllTenantsClick() {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.all}/${AdminRouteNames.tenant}`);
  }
}
