import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthAdminGuard } from '@suswoods/admin/auth-admin.guard';
import { AuthTenantGuard } from '@suswoods/tenant/auth-tenant.guard';
import { AppRouteNames } from '@suswoods/app-route-names.enum';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';

import { HomeComponent } from '@suswoods/public/home/home.component';
import { LoginComponent } from '@suswoods/public/login/login.component';
import { ContactUsComponent } from '@suswoods/public/contact-us/contact-us.component';
import { AboutUsComponent } from '@suswoods/public/about-us/about-us.component';
import { ResidentServicesComponent } from '@suswoods/public/resident-services/resident-services.component';
import { PublicLayoutComponent } from './public/public-layout/public-layout.component';

const routes: Routes = [
  {
    path: '', component: PublicLayoutComponent, children: [
      {
        path: AppRouteNames.home,
        component: HomeComponent
      },
      {
        path: AppRouteNames.contactUs,
        component: ContactUsComponent
      },
      {
        path: AppRouteNames.aboutUs,
        component: AboutUsComponent
      },
      {
        path: AppRouteNames.residentServices,
        component: ResidentServicesComponent
      },
      {
        path: AppRouteNames.login,
        component: LoginComponent
      },
    ]
  },
  {
    path: AdminRouteNames.root,
    canActivate: [AuthAdminGuard],
    loadChildren: '@suswoods/admin/admin.module#AdminModule'
  },
  {
    path: TenantRouteNames.root,
    canActivate: [AuthTenantGuard],
    loadChildren: '@suswoods/tenant/tenant.module#TenantModule'
  },
  {
    path: '**',
    redirectTo: AppRouteNames.home
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
