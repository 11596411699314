import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from '@suswoods/admin/admin-routing.module';
import { SharedModule } from '@suswoods/shared/shared.module';
import { TenantService } from '@suswoods/services';

import { AdminHomeComponent } from '@suswoods/admin/admin-home/admin-home.component';
import { TenantComponent } from '@suswoods/admin/tenant/tenant.component';
import { TenantTableComponent } from '@suswoods/admin/tenant/tenant-table/tenant-table.component';
import { TenantProfileComponent } from '@suswoods/admin/tenant/tenant-profile/tenant-profile.component';
import { TenantWaiversComponent } from '@suswoods/admin/tenant/tenant-waivers/tenant-waivers.component';
import { ViewWaiverComponent } from '@suswoods/admin/view-waiver/view-waiver.component';
import { BillsComponent } from '@suswoods/admin/bills/bills.component';
import { ViewBillComponent } from '@suswoods/admin/view-bill/view-bill.component';
import { TenantAddComponent } from '@suswoods/admin/tenant/tenant-add/tenant-add.component';
import { UnitComponent } from '@suswoods/admin/unit/unit.component';
import { UnitAddComponent } from '@suswoods/admin/unit/unit-add/unit-add.component';
import { UnitProfileComponent } from '@suswoods/admin/unit/unit-profile/unit-profile.component';
import { UnitTableComponent } from '@suswoods/admin/unit/unit-table/unit-table.component';
import { TenantAllComponent } from '@suswoods/admin/tenant/tenant-all/tenant-all.component';
import { TenantAllTableComponent } from '@suswoods/admin/tenant/tenant-all-table/tenant-all-table.component';
import { AddWaterBillComponent } from '@suswoods/admin/bills/add-water-bill/add-water-bill.component';
import { AddMiscBillComponent } from '@suswoods/admin/bills/add-misc-bill/add-misc-bill.component';
import { TenantAddOfflineComponent } from '@suswoods/admin/tenant/tenant-add-offline/tenant-add-offline.component';
import { OfflineBillsComponent } from '@suswoods/admin/bills/offline/offline-bills/offline-bills.component';
import { AddOfflineBillComponent } from '@suswoods/admin/bills/offline/add-offline-bill/add-offline-bill.component';
import { ViewOfflineBillsTableComponent } from '@suswoods/admin/bills/offline/view-offline-bills-table/view-offline-bills-table.component';
import { ReservationsComponent } from '@suswoods/admin/reservations/reservations.component';
import { AdminLayoutComponent } from '@suswoods/admin/admin-layout/admin-layout.component';
import { FileUploadComponent } from '@suswoods/admin/file-upload/file-upload.component';
import { ProgressComponent } from '@suswoods/admin/file-upload/progress/progress.component';
// import { AdminLayoutComponent } from './admin-layout/admin-layout.component';

@NgModule({
  declarations: [
    AdminHomeComponent,
    TenantComponent,
    TenantTableComponent,
    TenantProfileComponent,
    TenantWaiversComponent,
    ViewWaiverComponent,
    BillsComponent,
    ViewBillComponent,
    TenantAddComponent,
    UnitComponent,
    UnitAddComponent,
    UnitProfileComponent,
    UnitTableComponent,
    TenantAllComponent,
    TenantAllTableComponent,
    AddWaterBillComponent,
    AddMiscBillComponent,
    TenantAddOfflineComponent,
    OfflineBillsComponent,
    AddOfflineBillComponent,
    ViewOfflineBillsTableComponent,
    ReservationsComponent,
    AdminLayoutComponent,
    FileUploadComponent,
    ProgressComponent
    // AdminLayoutComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
  ],
  providers: [TenantService]
})
export class AdminModule { }
