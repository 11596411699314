import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { SubscriptionProfile } from '@suswoods/models';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http: HttpClient) { }
  // admin functions
  getSubscriptionsForTenant(id: number, type?: string) {
    const endPoint = type ? `${environment.apiUrl}/admin/subscriptions/${id}/${type}` : `${environment.apiUrl}/admin/subscriptions/${id}`;
    return this.http.get<SubscriptionProfile[]>(endPoint)
      .pipe(map(subscriptions => {
        return subscriptions;
      }));
  }

  removeSubscriptionForTenant(id: number) {
    const endPoint = `${environment.apiUrl}/admin/subscriptions/${id}`;
    return this.http.delete(endPoint)
      .pipe(map(subscriptions => {
        return subscriptions;
      }));
  }

  // tenant functions
  tenantGetSubscriptionsForTenant(id: number, type?: string) {
    const endPoint = type ? `${environment.apiUrl}/tenant/subscriptions/${id}/${type}` : `${environment.apiUrl}/tenant/subscriptions/${id}`;
    return this.http.get<SubscriptionProfile[]>(endPoint)
      .pipe(map(subscriptions => {
        return subscriptions;
      }));
  }

  tenantAddSubscriptionForTenant(id: number, type: string, data: object) {
    const endPoint = `${environment.apiUrl}/tenant/subscriptions/${id}/${type}`;
    return this.http.post<SubscriptionProfile[]>(endPoint, data)
      .pipe(map(subscriptions => {
        return subscriptions;
      }));
  }

  tenantRemoveSubscriptionForTenant(id: number, stripeSubId: string) {
    const endPoint = `${environment.apiUrl}/tenant/subscriptions/${id}/${stripeSubId}`;
    return this.http.delete(endPoint)
      .pipe(map(subscriptions => {
        return subscriptions;
      }));
  }

  
}
