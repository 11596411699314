export enum AdminRouteNames {
  root = 'admin',
  home = '',
  tenant = 'tenant',
  profile = 'profile',
  waivers = 'waivers',
  viewWaiver = 'view-waiver',
  bills = 'bills',
  viewBill = 'view-bill',
  add = 'add',
  addOffline = 'add-offline',
  unit = 'unit',
  all = 'all',
  water = 'water',
  miscBill = 'misc-bill',
  offline = 'offline',
  reservations = 'reservations'
}
