import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from '@angular/router';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { TenantService, AuthenticationService } from '@suswoods/services';
import { TenantProfile } from '@suswoods/models/tenant';
import { environment } from '@environments/environment';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';

@Component({
  selector: 'manage-add-card',
  templateUrl: './manage-card.component.html',
  styleUrls: ['./manage-card.component.scss']
})
export class ManageCardComponent implements OnInit {
  elements: Elements;
  card: StripeElement;
  tenant: TenantProfile;
  saving = false;
  removing = false;
  cardOnFile = false;
  achOnFile = false;
  error: string = null;
  manageBankAccount = `../${TenantRouteNames.manageAch}`;
 
  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: 'en'
  };
 
  stripeForm: FormGroup;
 
  constructor(private fb: FormBuilder,
              private stripeService: StripeService,
              private tenantService: TenantService,
              private authService: AuthenticationService,
              private router: Router) {}
 
  ngOnInit() {
    this.stripeForm = this.fb.group({
    });
    this.tenantService.tenantGetTenant(this.authService.currentUserValue.id).subscribe(tenant => { 
      this.tenant = tenant[0];
      if (this.tenant.stripeBankId) {
        this.achOnFile = true;
      }
      if (this.tenant.stripeCCId) {
        this.cardOnFile = true;
      } else if (this.tenant.stripeBankId == null) {
        this.achOnFile = false;
        this.initStripeForm();
      }
    });
  }

  initStripeForm() {
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#31325F',
                //lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: '#CFD7E0'
                }
              }
            }
          });
          this.card.mount('#card-element');
        }
      });
  }
 
  onAddCardClick() {
    this.saving = true;
    this.error = null;
    const name = this.authService.currentUserValue.firstName + ' ' + this.authService.currentUserValue.lastName;
    this.stripeService
      .createToken(this.card, { name })
      .subscribe(result => {
        if (result.token) {
          this.tenantService.updateStripeCCInfo({ 
            id: this.authService.currentUserValue.id,
            paymentInfo: result.token,
            stripeCustomerId: this.tenant.stripeId}).subscribe(result => {
              if (result['message'] == 'fail') {
                this.error = environment.defaultErrorMsg;
                this.saving = false;
              }
              //this.saving = false;
              location.reload();
            })
          
        } else if (result.error) {
          // Error creating the token
          //console.log(result.error.message);
          this.error = result.error.message;
          this.saving = false;
        }
      });
  }

  onRemoveCardClick() {
    this.removing = true;
    this.tenantService.removeStripeCCInfo({
      id: this.authService.currentUserValue.id,
      stripeCustomerId: this.tenant.stripeId,
      stripeCCId: this.tenant.stripeCCId,
      paymentInfo: {
        id: null,
        card: {
          id: null,
          brand: null,
          last4: null
        }
      }
    }).subscribe(result => {
      if (result['message'] === 'success') {
        //this.removing = false;
        location.reload();
      } else {
        alert(environment.defaultErrorMsg);
        this.removing = false;
      }
    })
  }

  onBackClick() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.home}`);
  }

}
