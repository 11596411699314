import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@suswoods/services';
import { AppRouteNames } from '@suswoods/app-route-names.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.isAdmin === 1) {
      // logged in
      return true;
    }

    // not logged in redirect to login with the return url
    this.router.navigate([AppRouteNames.login], { queryParams: { returnUrl: state.url } });
    return false;
  }
}