import { Component } from '@angular/core';
import { CalendarDayViewComponent } from 'angular-calendar';

@Component({
  selector: 'app-custom-day-view',
  templateUrl: './custom-day-view.component.html',
  styleUrls: ['./custom-day-view.component.scss']
})
export class CustomDayViewComponent extends CalendarDayViewComponent {
  eventWidth = 150;

  disableSegment(segment): Boolean {
    if (segment.date.getHours() < 6 || segment.date.getHours() > 21) return true;
    return false;
  }
}
