import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnitProfile } from '@suswoods/models';
import { UnitService } from '@suswoods/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';
import { environment } from '@environments/environment';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';

@Component({
  selector: 'app-unit-add',
  templateUrl: './unit-add.component.html',
  styleUrls: ['./unit-add.component.scss']
})
export class UnitAddComponent implements OnInit {
  unitData: UnitProfile;
  unitForm: FormGroup;
  isSaving = false;

  constructor(
    private route: ActivatedRoute,
    private unitService: UnitService,
    private fb: FormBuilder,
    private router: Router) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.unitForm = this.fb.group({
      address: ['', Validators.required],
      leaseAmount: ['', Validators.required],
    });
  }

  onSubmit() {
    this.isSaving = true;
    this.unitForm.patchValue({ address: this.unitForm.getRawValue().address.toUpperCase() });
    this.unitService.addUnit(this.unitForm.value)
      .pipe()
      .subscribe(data => {
        if (data['message'] === 'success') {
          this.router.navigateByUrl(`${AdminRouteNames.root}`);
        } else {
          alert(data['message']);
          this.isSaving = false;
        }
      }, error => {
        alert(environment.defaultErrorMsg);
        this.isSaving = false;
      });
  }

  goback() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.home}`);
  }

}
