import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TenantRouteNames } from '../../tenant-route-names.enum';
import { AuthenticationService } from '@suswoods/services';
import { TenantProfile } from '@suswoods/models';


@Component({
  selector: 'app-tenant-subscription',
  templateUrl: './tenant-subscription.component.html',
  styleUrls: ['./tenant-subscription.component.scss']
})
export class TenantSubscriptionComponent implements OnInit {
  tenant: TenantProfile;


  constructor(private router: Router, private authService: AuthenticationService) { }

  ngOnInit() {

  }

  onEnrollSubscriptionClick() {                         
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.subscription}/${TenantRouteNames.lease}/${TenantRouteNames.enroll}`);
  }

  onBackClick() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.home}`);
  }

}
