import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';
import {
  AuthenticationService,
  TenantService,
  UnitService,
  SubscriptionService
} from '@suswoods/services';
import { TenantProfile, UnitProfile, SubscriptionProfile } from '@suswoods/models';
import { ConfirmComponent } from '@suswoods/shared/dialog/confirm/confirm.component';


@Component({
  selector: 'app-tenant-subscription-lease-enroll',
  templateUrl: './tenant-subscription-lease-enroll.component.html',
  styleUrls: ['./tenant-subscription-lease-enroll.component.scss']
})
export class TenantSubscriptionLeaseEnrollComponent implements OnInit {
  confirmRef: MatDialogRef<ConfirmComponent>;
  tenant: TenantProfile;
  unit: UnitProfile;
  subscription: SubscriptionProfile;
  subscriptionData: TenantProfile;
  subscriptionForm: FormGroup;
  paymentType = '';
  paymentDescription = '';
  paymentName = '';
  paymentLast4 = '';
  isLoading = true;
  saving = false;
  removing = false;

  constructor(
    private authService: AuthenticationService,
    private tenantService: TenantService,
    private unitService: UnitService,
    private subService: SubscriptionService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private snack: MatSnackBar) { }

  ngOnInit() {
    this.initTenant();
  }

  initTenant() {
    this.tenantService.tenantGetTenant(this.authService.currentUserValue.id).subscribe(tenant => {      
      this.tenant = tenant[0];
      if (!this.tenant.stripePaymentToken) {
        this.isLoading = false;
        return;
      } 
      this.paymentType = this.tenant.stripeCCId ? 'Credit Card' : 'ACH Debit';
      this.paymentDescription = this.tenant.stripeCCId ? 'Card Type' : 'Bank Name';
      this.paymentName = this.tenant.stripeCCId ? this.tenant.stripeCCBrand : this.tenant.stripeBankName;
      this.paymentLast4 = this.tenant.stripeCCId ? this.tenant.stripeCCLastFour.toString() : this.tenant.stripeBankLastFour.toString();
      this.initUnit();
    });
  }

  initUnit() {
    this.unitService.tenantGetUnit(this.tenant.unitId).subscribe(unit => {
      if (unit[0]) {
        this.unit = unit[0];
        this.initSubscriptions();
      } else {
        this.isLoading = false;
      }
    });
  }

  initSubscriptions() {
    this.subService.tenantGetSubscriptionsForTenant(this.tenant.id, 'lease').subscribe(subscription => {
      if (subscription[0]) {
        this.subscription = subscription[0];
      }
      this.initForm();
    });
  }

  initForm() {
    this.subscriptionForm = this.fb.group({
      unitId: [{ value: this.unit.id, disabled: true }, Validators.required],
      leaseAmount: [{ value: '$' + this.unit.leaseAmount, disabled: true }, Validators.required],
      paymentType: [{ value: this.paymentType, disabled: true }, Validators.required],
      paymentLast4: [{ value: this.paymentLast4, disabled: true }, Validators.required],
      paymentName: [{ value: this.paymentName, disabled: true }, Validators.required],
    });
    this.isLoading = false;
  }

  onSubmit() {
    let message = '';
    if (!this.tenant.stripePaymentToken) {
      this.snack.open('Please add a Payment method first.', 'Got it', {
        duration: 5000
      });
    } else {
      if (this.paymentType === 'Credit Card') {
        message = `Are you sure you wish to enable lease subscription of $${this.unit.leaseAmount} ` +
          `and a credit card processing fee of $${this.unit.leaseAmount * this.unit.ccProcessingFee} ` +
          `per month?`
      } else {
        message = `Are you sure you wish to enable lease subscription of $${this.unit.leaseAmount} per month?`;
      }
      this.confirmSubDialog(
        'CONFIRM',
        message
      );
    }
  }

  saveSubscription() {
    this.saving = true;
    this.subService.tenantAddSubscriptionForTenant(this.tenant.id, 'lease', {
      amount: this.unit.leaseAmount,
      stripeCustomerId: this.tenant.stripeId,
      stripePlanId: this.unit.stripePlanId,
      stripeCCFeePlanId: this.unit.stripeCCFeePlanId,
      type: this.unit.id + ' LEASE',
      paymentType: this.paymentType
    }).subscribe(result => {
      location.reload();
    });
  }

  onRemoveSubClick() {
    this.confirmRemoveDialog(
      'CONFIRM',
      `Are you sure you wish to end this lease subscription?`
    );
  }

  removeSub() {
    this.removing = true;
    this.subService.tenantRemoveSubscriptionForTenant(this.subscription.id, this.subscription.stripeSubscriptionId).subscribe(result => {
      location.reload();
    });
  }

  onBackClick() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.subscription}`);
  }

  confirmSubDialog(header: string, message?: string) {
    this.confirmRef = this.dialog.open(ConfirmComponent, {
      disableClose: false
    });
    this.confirmRef.componentInstance.confirm = { header, message };
    this.confirmRef.afterClosed().subscribe(result => {
      if (result === true) { this.saveSubscription(); }
      this.confirmRef = null;
    });
  }

  confirmRemoveDialog(header: string, message?: string) {
    this.confirmRef = this.dialog.open(ConfirmComponent, {
      disableClose: false
    });
    this.confirmRef.componentInstance.confirm = { header, message };
    this.confirmRef.afterClosed().subscribe(result => {
      if (result === true) { this.removeSub(); }
      this.confirmRef = null;
    });
  }

}
