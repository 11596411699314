import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantProfile, UnitProfile, User } from '@suswoods/models';
import { TenantService, UnitService, UserService } from '@suswoods/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminRouteNames } from '../../admin-route-names.enum';

@Component({
  selector: 'app-tenant-profile',
  templateUrl: './tenant-profile.component.html',
  styleUrls: ['./tenant-profile.component.scss']
})
export class TenantProfileComponent implements OnInit {
  profileData: TenantProfile;
  profileForm: FormGroup;
  units: Array<UnitProfile> = [];
  currentUnit: string;
  isLoading = true;
  user:User;
  error: string;

  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private router: Router,
              private tenantService: TenantService,
              private unitService: UnitService,
              private userService: UserService) { }

  ngOnInit() {
    this.initUnits();
  }

  initUnits() {
    this.unitService.getUnits('empty').pipe().subscribe(units => {
      this.units = units;
      this.units.push({
        id: 'NONE',
        leaseAmount: 0,
        isOccupied: false
      });
      this.initTenant();
    },
    error => {
      this.isLoading = false
    });
  }

  initUser() {
    this.userService.getById(this.profileData.id).subscribe((result:any) => {
      this.user = result.data;
      this.initForm();
    });
  }
  
  initTenant() {
    this.route.paramMap.subscribe(params => {
      this.tenantService.getTenants(Number(params.get('id'))).pipe().subscribe(tenant => {
        if (!tenant[0]) {
          alert("Couldn't find tenant");
          return;
        }
        this.profileData = tenant[0];
        if (this.profileData.unitId) {
          this.currentUnit = this.profileData.unitId;
          this.units.push({
            id: this.profileData.unitId,
            leaseAmount: 0,
            isOccupied: true
          });
        }
        this.initUser();
      })
    });
  }

  initForm() {
    this.profileForm = this.fb.group({
      id: [this.profileData.id],
      unitId: [this.profileData.unitId],
      firstName: [this.profileData.firstName, Validators.required],
      lastName: [this.profileData.lastName, Validators.required],
      phone: [this.profileData.phone],
      username: [this.user.username],
      password: null,
      confirmPassword: null
    });
    this.isLoading = false;
  }
  

  onSubmit() {
    if (this.profileForm.get("password").dirty || this.profileForm.get("confirmPassword").dirty) {
      if(this.comparePasswords(this.profileForm.get("password").value, this.profileForm.get("confirmPassword").value)) {
        this.error = null;
      } else {
        this.error = "Passwords don't match";
        return 
      }
    }
    this.updateProfile();
  }

  updateProfile() {
    const profile: TenantProfile = {
      id: this.profileForm.get("id").value, 
      unitId: this.profileForm.get("unitId").value,
      firstName: this.profileForm.get("firstName").value,
      lastName: this.profileForm.get("lastName").value,
      phone: this.profileForm.get("phone").value
    }
    this.checkUnitChanges();
    this.tenantService.updateTenant(profile).subscribe(result => {
      this.updateUser();
  });
  }

  comparePasswords(password: string, confirmPassword: string): boolean {
    if (password === confirmPassword) {
      return true;
    }
    return false;
  }

  updateUser() {
    const user: User = {
      id: this.profileForm.get("id").value,
      isAdmin: 0,
      username: this.profileForm.get("username").value,
      isActive: this.user.isActive,
      created: this.user.created,
      password: this.profileForm.get("password").value
    } 
    this.userService.updateUser(user).subscribe(result => {
      this.router.navigateByUrl(`${AdminRouteNames.root}`);
    });
  }

  checkUnitChanges() {
    if (this.currentUnit !== this.profileForm.value.unitId) { // Changes were made
      if (this.currentUnit && this.currentUnit !== 'NONE') { // Update old unit
        this.updateUnitOccupancy(this.currentUnit, false);
      }
      if (this.profileForm.value.unitId !== 'NONE') { // Update new unit
        this.updateUnitOccupancy(this.profileForm.value.unitId, true);
      }
    }
  }

  updateUnitOccupancy(unitId: string, isOccupied: boolean) {
    const data = {
      unitId: unitId,
      isOccupied: isOccupied
    }
    this.unitService.updateUnitOccupancy(data).subscribe(result => {
      //console.log('update occupancy', result);
    }, err => {
      //console.log(err);
    });

  }

}
