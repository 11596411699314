import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EventDialogData } from '@suswoods/models/event-dialog-data';
import { EventTypes,
  PartySettings,
  MovieSettings,
  GamingSettings,
  ComputerSettings,
  GymSettings,
  RoomSettings } from '@suswoods/models/event-calendar';
import { AuthenticationService } from '@suswoods/services/authentication.service';
import { CovidComponent } from '@suswoods/shared/waivers/covid/covid.component';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {

  EventTypes = EventTypes;
  eventTypes = Object.values(EventTypes)
  eventRegex = /(Party Room|Theater Room|Gaming Room|Computer Room|Gym Room)/;
  eventType: RoomSettings;
  eventForm: FormGroup;
  isSaving = false;

  constructor(public dialogRef: MatDialogRef<CreateEventComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public eventData: EventDialogData,
              private authService: AuthenticationService,
              public dialog: MatDialog) {}


  ngOnInit() {
    this.initForm();
  }

  onNoClick(): void { // this was in the angular docs, but doesn't appear to do anything
    this.dialogRef.close();
  }

  initForm() {
    this.eventForm = this.fb.group({
      date: [{value: this.eventData.startDate.toLocaleDateString(), disabled: true}, Validators.required],
      time: [{value: this.eventData.startDate.toLocaleTimeString(), disabled: true}, Validators.required],
      iAgree: [false],
      iAgreeCovid: [false, Validators.requiredTrue],
      isPrivate: [false],
      type: [this.eventTypes[0], Validators.pattern(this.eventRegex)]
    });
  }

  onSelectionChange(event) {
    if (event.value === EventTypes.Party) {
      this.eventType = PartySettings;
      this.eventForm.patchValue({isPrivate: false});
      this.eventForm.controls['isPrivate'].enable();
      this.eventForm.controls['isPrivate'].updateValueAndValidity();
    }
    if (event.value === EventTypes.Movie) {
      this.eventType = MovieSettings;
      this.eventForm.patchValue({isPrivate: true});
      this.eventForm.controls['isPrivate'].disable();
      this.eventForm.controls['isPrivate'].updateValueAndValidity();
    }
    if (event.value === EventTypes.Gaming) {
      this.eventType = GamingSettings;
      this.eventForm.patchValue({isPrivate: true});
      this.eventForm.controls['isPrivate'].disable();
      this.eventForm.controls['isPrivate'].updateValueAndValidity();
    }
    if (event.value === EventTypes.Computer) {
      this.eventType = ComputerSettings;
      this.eventForm.patchValue({isPrivate: true});
      this.eventForm.controls['isPrivate'].disable();
      this.eventForm.controls['isPrivate'].updateValueAndValidity();
    }
    if (event.value === EventTypes.Gym) {
      this.eventType = GymSettings;
      this.eventForm.patchValue({isPrivate: true});
      this.eventForm.controls['isPrivate'].disable();
      this.eventForm.controls['isPrivate'].updateValueAndValidity();
    }
  }

  onIsPrivateChange(event) {
    if (event.checked === true) {
      this.eventForm.controls['iAgree'].setValidators([Validators.requiredTrue]);
      this.eventForm.controls['iAgree'].updateValueAndValidity();
    } else {
      this.eventForm.controls['iAgree'].setValidators([]);
      this.eventForm.controls['iAgree'].updateValueAndValidity();
    }
  }

  onSubmit() {
    this.isSaving = true;
    this.eventData = {
      startDate: this.eventData.startDate,
      endDate: new Date(this.eventData.startDate.getTime() + this.eventType.duration),
      title: this.eventForm.getRawValue().type,
      bookedBy: this.authService.currentUserValue.firstName + ' ' + this.authService.currentUserValue.lastName,
      status: 'saved',
      price: this.eventType.price,
      fee: this.eventType.fee,
      isPrivate: this.eventForm.getRawValue().isPrivate
    };
    this.isSaving = false;
    this.dialogRef.close(this.eventData);
  }

  onViewCovidWarningClick() {
    const covidRef = this.dialog.open(CovidComponent, {disableClose: true});
  }

}
