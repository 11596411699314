import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BillService } from '@suswoods/services';
import { TenantProfile } from '@suswoods/models';

interface Bills {
  billingDate: string;
  amountDue: string;
  type: string;
}

@Component({
  selector: 'app-view-offline-bills-table',
  templateUrl: './view-offline-bills-table.component.html',
  styleUrls: ['./view-offline-bills-table.component.scss']
})
export class ViewOfflineBillsTableComponent implements OnInit {
  @Input() tenant: TenantProfile;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  bills = [];
  displayedColumns: string[] = ['billingDate', 'amountDue', 'type'];
  dataSource = new MatTableDataSource<Bills>(this.bills);

  constructor(private billService: BillService) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.initBills();
  }

  initBills() {
    this.billService.getOfflineBills(this.tenant.id).subscribe((bills) => {
      this.bills = bills;
      this.dataSource = new MatTableDataSource<Bills>(this.bills);
    });
  }

}
