import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Waivers } from '@suswoods/models/waivers.enum';
import { TenantService, AuthenticationService, WaiverService } from '@suswoods/services';
import { TenantProfile, TenantWaivers } from '@suswoods/models';
import { Router } from '@angular/router';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';

@Component({
  selector: 'app-manage-waviers',
  templateUrl: './manage-waviers.component.html',
  styleUrls: ['./manage-waviers.component.scss']
})
export class ManageWaviersComponent implements OnInit {
  waivers = [
    {
      name: Waivers.amenities,
      completeDate: null,
    },
    {
      name: Waivers.clubhouse,
      completeDate: null,
    },
    {
      name: Waivers.pet,
      completeDate: null,
    },
    {
      name: Waivers.pool,
      completeDate: null,
    },
    {
      name: Waivers.satellitedish,
      completeDate: null,
    },
    {
      name: Waivers.theather,
      completeDate: null,
    }
  ];

  currentWaiver: string = null;
  currentWaiverStatus = false;
  completedWaivers: TenantWaivers[];
  waiverForm: FormGroup;
  tenant: TenantProfile;
  isSaving = false;


  constructor(
    private fb: FormBuilder,
    private tenantService: TenantService,
    private authService: AuthenticationService,
    private waiverService: WaiverService,
    private router: Router) { }

  ngOnInit() {
    this.initForm();
    this.initTenant();
    this.initWaivers();
  }

  initForm() {
    this.waiverForm = this.fb.group({
      iAgree: [false, Validators.requiredTrue],
    });
  }

  initCompletedForm() {
    this.waiverForm = this.fb.group({
      iAgree: [{ value: true, disabled: true }, Validators.requiredTrue],
    });
  }

  initTenant() {
    this.tenantService.tenantGetTenant(this.authService.currentUserValue.id).subscribe(tenant => {
      this.tenant = tenant[0];
    });
  }

  initWaivers() {
    this.waiverService.tenantGetWaiver().subscribe((waivers) => {
      this.completedWaivers = waivers;
      this.addCompletedWaivers();
    });
  }

  addCompletedWaivers() {
    this.completedWaivers.forEach(cWaiver => {
      this.waivers.forEach(waiver => {
        if (waiver.name === cWaiver.type) {
          waiver.completeDate = cWaiver.completeDate;
        }
      });
    });
  }

  onClickUncompleteWaiver(waiver: string) {
    this.currentWaiver = waiver;
    this.currentWaiverStatus = false;
  }

  onClickCompleteWaiver(waiver: string) {
    this.initCompletedForm();
    this.currentWaiver = waiver;
    this.currentWaiverStatus = true;
  }

  onBackClick() {
    this.currentWaiver = null;
    this.initForm();
  }

  onSubmit() {
    const data = {
      userId: this.tenant.id,
      type: this.currentWaiver,
      completeDate: new Date()
    }
    this.waiverService.saveWaiver(data).subscribe((result) => {
      this.initForm();
      this.currentWaiver = null;
    });
    this.waivers.forEach(waiver => {
      if (waiver.name == this.currentWaiver) {
        waiver.completeDate = new Date();
      }
    });
  }

  onHomeClick() {
    this.router.navigateByUrl(`${TenantRouteNames.root}/${TenantRouteNames.home}`);
  }

}
