import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppRouteNames } from '@suswoods/app-route-names.enum';
import { AuthenticationService } from '@suswoods/services';
import { ThrowStmt } from '@angular/compiler';
import { User } from '@suswoods/models';
import { filter, map } from 'rxjs/operators';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  currentUser: User;
  public innerWidth: any;
  title = 'Dashboard';

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  constructor(private router: Router, private authService: AuthenticationService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.currentUser = this.authService.currentUserValue;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data.title) {
            return child.snapshot.data.title;
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe((data: any) => {
      if (data) {
        this.title = data;
      }
    });
  }


  onLogoutClick() {
    this.authService.logout();
    this.router.navigateByUrl(AppRouteNames.home);
  }

  toggleSidebar() {
    const ele = document.getElementById('wrapper');
    if (this.innerWidth > 980) {
      ele.classList.remove('toggled');
    } else {
      ele.classList.toggle('toggled');
    }
  }

}
