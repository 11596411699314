import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TenantProfile } from '@suswoods/models';
import { TenantService } from '@suswoods/services';
import { AdminRouteNames } from '@suswoods/admin/admin-route-names.enum';
import { UserService } from '@suswoods/services';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss']
})
export class TenantComponent implements OnInit {
  profileData: TenantProfile;
  isLoading = true;
  isDisabling = false;
  isEnabling = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tenantService: TenantService,
    private userService: UserService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.tenantService.getTenants(Number(params.get('id'))).pipe().subscribe(tenant => {
        if (!tenant[0]) {
          alert(environment.defaultErrorMsg);
          this.isLoading = false;
          return;
        }
        this.profileData = tenant[0];
        this.isLoading = false;
      }, err => {
        alert(environment.defaultErrorMsg);
        this.isLoading = false;
      });
    });


  }




  onProfileClick() {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.tenant}/${AdminRouteNames.profile}/${this.profileData.id}`);
  }

  onWaiversClick() {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.tenant}/${AdminRouteNames.waivers}/${this.profileData.id}`);
  }

  onBillsClick() {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.tenant}/${AdminRouteNames.bills}/${this.profileData.id}`);
  }

  onOfflineBillsClick() {
    this.router.navigateByUrl(`${AdminRouteNames.root}/${AdminRouteNames.tenant}/${AdminRouteNames.offline}/${AdminRouteNames.bills}/${this.profileData.id}`);
  }

  onDisableUserClick() {
    this.isDisabling = true;
    this.userService.disableUser(this.profileData.id).subscribe(disabled => {
      if (disabled['message'] === 'success') {
        this.profileData.isActive = false;
        this.isDisabling = false;
      } else {
        alert(environment.defaultErrorMsg);
        this.isDisabling = false;
      }
    }, err => {
      alert(environment.defaultErrorMsg);
      this.isDisabling = false;
    });
  }

  onEnableUserClick() {
    this.isEnabling = true;
    this.userService.enableUser(this.profileData.id).subscribe(enabled => {
      if (enabled['message'] === 'success') {
        this.profileData.isActive = true;
        this.isEnabling = false;
      } else {
        alert(environment.defaultErrorMsg);
        this.isEnabling = false;
      }
    }, err => {
      alert(environment.defaultErrorMsg);
      this.isEnabling = false;
    });
  }

}
