import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { TenantRouteNames } from '@suswoods/tenant/tenant-route-names.enum';

import { TenantHomeComponent } from '@suswoods/tenant/tenant-home/tenant-home.component';
import { ManageCardComponent } from '@suswoods/tenant/payment/manage-card/manage-card.component';
import { ManageAchComponent } from '@suswoods/tenant/payment/manage-ach/manage-ach.component';
import { TenantSubscriptionComponent } from '@suswoods/tenant/subscription/tenant-subscription/tenant-subscription.component';
import { TenantSubscriptionLeaseEnrollComponent } from '@suswoods/tenant/subscription/tenant-subscription-lease-enroll/tenant-subscription-lease-enroll.component';
import { ViewAllBillsComponent } from '@suswoods/tenant/bill/view-all-bills/view-all-bills.component';
import { EventCalendarComponent } from '@suswoods/shared/event-calendar/event-calendar.component';
import { ManageWaviersComponent } from '@suswoods/tenant/manage-waviers/manage-waviers.component';
import { TenantLayoutComponent } from '@suswoods/tenant/tenant-layout/tenant-layout.component';
import { RequestForMaintainessComponent } from '@suswoods/tenant/request-for-maintainess/request-for-maintainess.component';
import { RequestForConciergeComponent } from '@suswoods/tenant/request-for-concierge/request-for-concierge.component';

const tenantRoutes: Routes = [
  {
    path: '', component: TenantLayoutComponent, children: [
      {
        path: TenantRouteNames.home,
        component: TenantHomeComponent
      },
      {
        path: `${TenantRouteNames.payment}/${TenantRouteNames.manageCard}`,
        component: ManageCardComponent
      },
      {
        path: `${TenantRouteNames.payment}/${TenantRouteNames.manageAch}`,
        component: ManageAchComponent
      },
      {
        path: `${TenantRouteNames.manageWaviers}`,
        component: ManageWaviersComponent
      },
      {
        path: `${TenantRouteNames.subscription}`,
        component: TenantSubscriptionComponent
      },
      {
        path: `${TenantRouteNames.subscription}/${TenantRouteNames.lease}/${TenantRouteNames.enroll}`,
        component: TenantSubscriptionLeaseEnrollComponent
      },
      {
        path: `${TenantRouteNames.bill}/${TenantRouteNames.viewAll}`,
        component: ViewAllBillsComponent
      },
      {
        path: `${TenantRouteNames.calendar}`,
        component: EventCalendarComponent
      },
      {
        path: `${TenantRouteNames.maintenanceRequest}`,
        component: RequestForMaintainessComponent
      },
      {
        path: `${TenantRouteNames.conciergeRequest}`,
        component: RequestForConciergeComponent
      },
      {
        path: '**',
        redirectTo: TenantRouteNames.home
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(tenantRoutes)
  ],
  exports: [RouterModule]
})
export class TenantRoutingModule { }